import {
	forwardRef,
	PropsWithChildren,
	useMemo,
} from 'react';

interface Props {
	p?: number;
	px?: number;
	py?: number;
	pt?: number;
	pr?: number;
	pb?: number;
	pl?: number;
	m?: number;
	mx?: number;
	my?: number;
	mt?: number;
	mr?: number;
	mb?: number;
	ml?: number;
	border?: boolean;
	rounded?: boolean;
	className?: string;
	style?: Record<string, any>;
}

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
	p,
	px,
	py,
	pt,
	pr,
	pb,
	pl,
	m,
	mx,
	my,
	mt,
	mr,
	mb,
	ml,
	border,
	rounded,
	className,
	style,
	children,
}, ref: any) => {
	const defaultClassName = useMemo(() => {
		const classNames = [];

		if (border) {
			classNames.push('border border-gray');
		}

		if (rounded) {
			classNames.push('rounded');
		}

		if (p) {
			classNames.push(`p-${p}`);
		}

		if (px) {
			classNames.push(`px-${px}`);
		}

		if (py) {
			classNames.push(`py-${py}`);
		}

		if (pt) {
			classNames.push(`pt-${pt}`);
		}

		if (pr) {
			classNames.push(`pr-${pr}`);
		}

		if (pb) {
			classNames.push(`pb-${pb}`);
		}

		if (pl) {
			classNames.push(`pl-${pl}`);
		}

		if (m) {
			classNames.push(`m-${m}`);
		}

		if (mx) {
			classNames.push(`mx-${mx}`);
		}

		if (my) {
			classNames.push(`my-${my}`);
		}

		if (mt) {
			classNames.push(`mt-${mt}`);
		}

		if (mr) {
			classNames.push(`mr-${mr}`);
		}

		if (mb) {
			classNames.push(`mb-${mb}`);
		}

		if (ml) {
			classNames.push(`ml-${ml}`);
		}

		return [
			...classNames,
			className,
		];
	}, [p, px, py, pt, pr, pb, pl, m, mx, my, mt, mr, mb, ml, border, rounded, className]);

	return (
		<div className={defaultClassName.join(' ')} style={style} ref={ref}>
			{children}
		</div>
	);
});
