import { FormikProvider, useFormik } from 'formik';
import {
	Route,
	Routes,
	useNavigate,
} from 'react-router-dom';

import Create from '../Create';
import Preview from '../Preview';
import Start from '../Start';

export interface FormValues {
	name?: string;
	position?: string;
	role?: string;
	phone?: string;
	mobile?: string;
	email?: string;
	note?: string;
}

const App = () => {
	const navigate = useNavigate();

	const validate = (values: FormValues) => {
		const errors: FormValues = {};

		if (!values.name) {
			errors.name = 'Ungültiger Name';
		}

		if (!values.position) {
			errors.position = 'Ungültige Position';
		}

		if (!values.phone) {
			errors.phone = 'Ungültige Telefonnummer';
		}

		if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Ungültige E-Mail';
		}

		return errors;
	};

	const formik: any = useFormik({
		initialValues: {
			name: '',
			position: '',
			role: '',
			phone: '',
			mobile: '',
			email: '',
			note: '',
		},
		validate,
		onSubmit: () => navigate('/preview'),
	});

	return (
		<FormikProvider value={formik}>
			<Routes>
				<Route path="/" element={<Start />} />
				<Route path="/create" element={<Create />} />
				<Route path="/preview" element={<Preview />} />
			</Routes>
		</FormikProvider>
	);
};

export default App;
