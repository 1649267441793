import clsx from 'clsx';
import { PropsWithChildren, useMemo } from 'react';

interface Props {
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
	component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
	fontSize?:
	| 'text-xs'
	| 'text-sm'
	| 'text-base'
	| 'text-lg'
	| 'text-xl'
	| 'text-2xl'
	| 'text-3xl'
	| 'text-4xl'
	| 'text-5xl'
	| 'text-6xl'
	| 'text-7xl'
	| 'text-8xl'
	| 'text-9xl';
	fontWeight?:
	| 'font-thin'
	| 'font-extralight'
	| 'font-light'
	| 'font-normal'
	| 'font-medium'
	| 'font-semibold'
	| 'font-bold'
	| 'font-extrabold'
	| 'font-black';
	fontFamily?: 'font-sans' | 'font-serif' | 'font-mono';
	tracking?: 'tracking-tighter' | 'tracking-tight' | 'tracking-normal' | 'tracking-wide' | 'tracking-wider' | 'tracking-widest';
	leading?:
	| 'leading-3'
	| 'leading-4'
	| 'leading-5'
	| 'leading-6'
	| 'leading-7'
	| 'leading-8'
	| 'leading-9'
	| 'leading-10'
	| 'leading-none'
	| 'leading-tight'
	| 'leading-snug'
	| 'leading-normal'
	| 'leading-relaxed'
	| 'leading-loose';
	className?: string;
}

export const Typography = ({
	children,
	variant,
	component,
	fontSize,
	fontWeight,
	fontFamily,
	tracking,
	leading,
	className,
}: PropsWithChildren<Props>) => {
	const Component = component || variant || 'p';

	const defaultClassName = className?.includes('text-') ? '' : 'text-black';

	const fontSizeClassName = useMemo(() => {
		let classNames;
		switch (variant || component) {
			case 'h1':
				classNames = 'text-4xl';
				break;
			case 'h2':
				classNames = 'text-3xl';
				break;
			case 'h3':
				classNames = 'text-2xl';
				break;
			case 'h4':
				classNames = 'text-xl';
				break;
			default:
				classNames = fontSize;
		}
		return classNames;
	}, [fontSize, variant, component]);

	const fontWeightClassName = useMemo(() => {
		let classNames;
		switch (variant || component) {
			case 'h1':
			case 'h2':
			case 'h3':
			case 'h4':
				classNames = fontWeight || 'font-bold';
				break;
			case 'h5':
			case 'h6':
				classNames = fontWeight || 'font-medium';
				break;
			default:
				classNames = fontWeight;
		}
		return classNames;
	}, [fontWeight, variant, component]);

	const trackingClassName = useMemo(() => {
		let classNames;
		switch (variant || component) {
			case 'h1':
			case 'h2':
			case 'h3':
			case 'h4':
				classNames = 'tracking-tight';
				break;
			default:
				classNames = tracking;
		}
		return classNames;
	}, [tracking, variant, component]);

	return (
		<Component
			className={clsx([
				fontSizeClassName,
				fontWeightClassName,
				fontFamily,
				trackingClassName,
				leading,
				defaultClassName,
				className,
				'font-[circular]',
			])}
		>
			{children}
		</Component>
	);
};
