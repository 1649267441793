import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { FormValues } from '../App/App';
import { Box } from '../Box';
import { Button } from '../Button';
import { Pulpo } from '../Pulpo';
import { Typography } from '../Typography';

const Start = () => {
	const { resetForm } = useFormikContext<FormValues>();

	useEffect(() => resetForm(), [resetForm]);

	return (
		<div className="flex flex-col items-center justify-center w-screen h-screen">
			<Box mb={8} className="flex flex-col items-center">
				<Box mb={2} className="text-primary">
					<Pulpo />
				</Box>
				<Typography variant="h1" className="text-primary">Richnerstutz</Typography>
				<Typography variant="h2" fontWeight="font-normal" className="text-primary">Signaturen</Typography>
			</Box>
			<Link to="/create"><Button>Signatur erstellen</Button></Link>
		</div>
	);
};

export default Start;
