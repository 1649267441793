import { FormikErrors } from 'formik';
import {
	ChangeEvent,
	forwardRef,
	ReactElement,
	useCallback,
	useMemo,
} from 'react';

import { ErrorBox } from '../ErrorBox';

export enum FormInputType {
	TEXT = 'text',
	DATE = 'date',
	PASSWORD = 'password',
	EMAIL = 'email',
}

interface Props {
	id?: string;
	name?: string;
	label?: string;
	value?: string | number;
	type?: FormInputType;
	placeholder?: string;
	componentBefore?: ReactElement | string;
	componentAfter?: ReactElement | string;
	disabled?: boolean;
	hidden?: boolean;
	focusEnbaled?: boolean;
	backgroundColor?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onChangeValue?: (value: string) => void;
	onBlur?: (e: any) => void;
	errors?: string | false | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined;
}

export const FormInput = forwardRef((
	{
		id,
		name,
		label,
		value,
		type = FormInputType.TEXT,
		placeholder,
		componentBefore,
		componentAfter,
		disabled,
		hidden,
		focusEnbaled = true,
		backgroundColor = 'bg-white',
		onChange,
		onChangeValue,
		onBlur,
		errors,
	}: Props,
	ref: any,
) => {
	const labelWrapperClassName = useMemo(() => [hidden && 'hidden'], [hidden]);

	const inputWrapperClassName = useMemo(() => {
		const classNames = [
			'flex',
			'items-center',
			'justify-center',
			'w-full',
			'rounded',
			'ring-1',
			'ring-inset',
			'ring-black',
			'ring-opacity-20',
			backgroundColor,
		];

		if (focusEnbaled) {
			classNames.push(
				'focus-within:ring-2',
				'focus-within:ring-inset',
				'focus-within:ring-primary',
			);
		}

		return classNames;
	}, [backgroundColor, focusEnbaled]);

	const labelClassName = useMemo(() => [
		'mb-2',
		'inline-block',
		'text-black',
		'font-[circular]',
	], []);

	const inputClassName = useMemo(
		() => [
			'outline-none',
			'ring-0',
			'bg-transparent',
			'w-full',
			'p-3',
			'text-black',
			disabled && 'text-opacity-50',
			disabled && 'cursor-not-allowed',
			'placeholder-black',
			'placeholder-opacity-25',
			'font-[circular]',
		],
		[disabled],
	);

	const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		if (onChangeValue) {
			onChangeValue(e.target.value);
		} else if (onChange) {
			onChange(e);
		}
	}, [onChange, onChangeValue]);

	return (
		<>
			<label htmlFor={id} className={labelWrapperClassName.join(' ')}>
				{label && <span className={labelClassName.join(' ')}>{label}</span>}
				<div className={inputWrapperClassName.join(' ')}>
					{componentBefore}
					<input
						ref={ref}
						type={type}
						id={id}
						name={name}
						className={inputClassName.join(' ')}
						value={value}
						onChange={handleOnChange}
						onBlur={onBlur}
						placeholder={placeholder}
						autoComplete="chrome-off"
						disabled={disabled}
					/>
					{componentAfter}
				</div>
				{errors && <ErrorBox errors={errors} />}
			</label>
		</>
	);
});
