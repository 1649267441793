import clsx from 'clsx';
import { FormikErrors } from 'formik';
import React from 'react';

interface Props {
	errors: string | false | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined;
	className?: string;
}

export const ErrorBox = ({ errors, className }: Props) => (
	<div className={clsx('flex items-center mt-2 p-2 border border-primary text-primary rounded', className)}>
		<span className="font-medium ml-1.5">{errors}</span>
	</div>
);
