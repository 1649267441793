import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';

import { FormValues } from '../App/App';
import { Box } from '../Box';
import { Button } from '../Button';
import { FormInput } from '../FormInput';

const Create = () => {
	const { values, handleChange, handleSubmit, errors, touched } = useFormikContext<FormValues>();

	return (
		<div className="flex flex-col items-center justify-center w-screen h-screen">
			<Box p={4} className="w-full max-w-[720px]">
				<Box p={4} mb={4} className="relative border border-gray-100 rounded">
					<Box mb={4}>
						<FormInput
							label="Name"
							placeholder="Vorname und Nachname"
							id="name"
							name="name"
							value={values.name}
							onChange={handleChange}
							errors={touched.name && errors.name}
						/>
					</Box>
					<Box mb={4}>
						<FormInput
							label="Position"
							placeholder="Offizielle Position (HR)"
							id="position"
							name="position"
							value={values.position}
							onChange={handleChange}
							errors={touched.position && errors.position}
						/>
					</Box>
					<Box mb={4}>
						<FormInput
							label="Zusatzfunktion (optional)"
							placeholder="Offizielle Zusatzfunktion (HR)"
							id="role"
							name="role"
							value={values.role}
							onChange={handleChange}
							errors={touched.role && errors.role}
						/>
					</Box>
					<Box mb={4}>
						<FormInput
							label="Telefon"
							placeholder="Telefonnummer (+41 56 616 67 67)"
							id="phone"
							name="phone"
							value={values.phone}
							onChange={handleChange}
							errors={touched.phone && errors.phone}
						/>
					</Box>
					<Box mb={4}>
						<FormInput
							label="Mobil (optional)"
							placeholder="Mobilnummer (+41 79 123 456 789)"
							id="mobile"
							name="mobile"
							value={values.mobile}
							onChange={handleChange}
							errors={touched.mobile && errors.mobile}
						/>
					</Box>
					<Box mb={4}>
						<FormInput
							label="E-Mail"
							placeholder="Geschäftliche E-Mail-Adresse"
							id="email"
							name="email"
							value={values.email}
							onChange={handleChange}
							errors={touched.email && errors.email}
						/>
					</Box>
					<Box mb={2}>
						<FormInput
							label="Hinweis (optional)"
							placeholder="Wir sind umgezogen."
							id="note"
							name="note"
							value={values.note}
							onChange={handleChange}
							errors={touched.note && errors.note}
						/>
					</Box>
				</Box>
				<Box className="flex justify-between">
					<Link to="/">
						<Button>Zurück</Button>
					</Link>
					<Button onClick={handleSubmit}>Erstellen</Button>
				</Box>
			</Box>
		</div>
	);
};

export default Create;
