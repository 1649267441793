import {
	useEffect,
	useMemo,
	useState,
} from 'react';

interface Props {
	color?: 'light' | 'dark' | 'primary';
}

const SUSPEND_TIMEOUT = 0;

export const Spinner = ({ color = 'primary' }: Props) => {
	const [suspend, setSuspend] = useState(false);

	const classNames = useMemo(() => {
		const defaultClassNames = [
			'animate-spin',
			'h-4',
			'w-4',
		];

		switch (color) {
			case 'light':
				defaultClassNames.push('text-white');
				break;
			case 'dark':
				defaultClassNames.push('text-black');
				break;
			default:
				defaultClassNames.push('text-primary');
				break;
		}

		return defaultClassNames;
	}, [color]);

	useEffect(() => {
		const suspendTimeout = setTimeout(() => setSuspend(true), SUSPEND_TIMEOUT);

		return () => clearTimeout(suspendTimeout);
	}, []);

	if (!suspend) {
		return null;
	}

	return (
		<svg
			fill="none"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames.join(' ')}
		>
			<circle
				cx="12"
				cy="12"
				r="10"
				strokeWidth="4"
				stroke="currentColor"
				className="opacity-25"
			/>
			<path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			/>
		</svg>
	);
};
