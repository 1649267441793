import {
	forwardRef,
	PropsWithChildren,
	useMemo,
} from 'react';

interface Props {
	id?: string;
	width?: string;
}

const Table = forwardRef<HTMLTableElement, PropsWithChildren<Props>>((props, ref) => {
	const { width, children } = props;

	const tableStyle = useMemo<any>(() => ({
		border: 0,
		borderSpacing: 0,
		borderWidth: 0,
		borderColor: 'transparent',
		cellPadding: 0,
		cellSpacing: 0,
		borderCollapse: 'collapse',
		padding: '0px',
		margin: '0px',
		...(width && { width }),
	}), [width]);

	return (
		<table
			ref={ref}
			cellPadding="0"
			cellSpacing="0"
			style={{ ...tableStyle }}
			{...props}
		>
			<tbody>
				{children}
			</tbody>
		</table>
	);
});

export default Table;
