import { ReactComponent as Image } from '../../../../asset/image/pulpo.svg';

interface Props {
	width?: number;
	height?: number;
}

export const Pulpo = ({ width = 98, height = 82 }: Props) => (
	<Image width={width} height={height} />
);
