import clsx from 'clsx';
import { ReactElement, useMemo } from 'react';

import { Spinner } from '../Spinner';

export enum ButtonVariant {
	PRIMARY = 'primary',
	PRIMARY_GHOST = 'primary-ghost',
	SECONDARY = 'secondary',
	SECONDARY_GHOST = 'secondary-ghost',
	SUCCESS = 'success',
	SUCCESS_GHOST = 'success-ghost',
}

export enum ButtonType {
	SUBMIT = 'submit',
}

interface ButtonProps {
	children?: ReactElement | string;
	type?: 'button' | 'submit' | 'reset' | undefined;
	variant?: ButtonVariant | 'primary' | 'primary-ghost' | 'secondary' | 'secondary-ghost' | 'success' | 'success-ghost';
	size?: 'medium' | 'small';
	icon?: ReactElement;
	block?: boolean;
	onClick?: () => void;
	className?: string;
	tabIndex?: number;
	disabled?: boolean;
	loading?: boolean;
}

export const Button = ({
	children,
	type = 'submit',
	variant = ButtonVariant.PRIMARY,
	icon,
	size = 'medium',
	block = false,
	onClick,
	className,
	tabIndex,
	disabled,
	loading,
}: ButtonProps) => {
	const buttonClassName = useMemo(() => {
		let classNames: string[] = [];

		switch (variant) {
			case ButtonVariant.PRIMARY:
				classNames.push(
					'text-white',
					'bg-primary',
					'hover:bg-opacity-75',
					'outline-none',
					'focus:outline-none',
					'ring-0',
					'focus:ring-0',
				);
				break;
			case ButtonVariant.PRIMARY_GHOST:
				classNames.push(
					'text-primary',
					'bg-transparent',
					'outline-none',
					'focus:outline-none',
					'ring-1',
					'ring-inset',
					'ring-primary',
					'hover:opacity-75',
				);
				break;
			case ButtonVariant.SECONDARY:
				classNames.push(
					'text-white',
					'bg-black',
					'hover:opacity-75',
					'outline-none',
					'focus:outline-none',
					'ring-0',
					'focus:ring-0',
				);
				break;
			case ButtonVariant.SECONDARY_GHOST:
				classNames.push(
					'text-black',
					'text-opacity-50',
					'bg-transparent',
					'outline-none',
					'focus:outline-none',
					'ring-1',
					'ring-inset',
					'ring-black',
					'ring-opacity-25',
					'hover:ring-primary',
					'hover:text-primary',
				);
				break;
			case ButtonVariant.SUCCESS:
				classNames.push(
					'text-white',
					'bg-green',
					'hover:opacity-75',
					'outline-none',
					'focus:outline-none',
					'ring-0',
					'focus:ring-0',
				);
				break;
			case ButtonVariant.SUCCESS_GHOST:
				classNames.push(
					'text-green',
					'bg-transparent',
					'outline-none',
					'focus:outline-none',
					'ring-1',
					'ring-inset',
					'ring-green',
					'hover:opacity-75',
				);
				break;
			default:
				classNames = [];
		}

		if (block) {
			classNames.push('w-full');
		}

		switch (size) {
			case 'small':
				classNames.push('p-2', 'text-sm');
				break;
			default:
				classNames.push('p-3');
		}

		return classNames;
	}, [variant, block, size]);

	const buttonLabel = useMemo(() => {
		if (!icon) {
			return children;
		}

		const iconClassNames: string[] = [];

		if (!block) {
			iconClassNames.push('ml-2');
		}

		return (
			<span className="flex items-center justify-between">
				<span className="flex">{children}</span>
				<span className={clsx(['flex', iconClassNames])}>{icon}</span>
			</span>
		);
	}, [icon, children, block]);

	return (
		<button
			type={type}
			onClick={onClick}
			className={clsx([
				'rounded',
				'font-medium',
				'disabled:opacity-25',
				'disabled:cursor-not-allowed',
				'font-[circular]',
				...buttonClassName,
				className,
			])}
			tabIndex={tabIndex}
			disabled={disabled}
		>
			{loading ? (
				<div className="flex items-center justify-center">
					<Spinner color="light" />
				</div>
			) : buttonLabel}
		</button>
	);
};
