import './asset/style/index.scss';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';

import App from './app/view/component/App';

const Root = () => (
	<HashRouter>
		<App />
	</HashRouter>
);

render(<Root />, document.getElementById('root'));
