import { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '../Box';
import { Button } from '../Button';
import Template from '../Template';

const Preview = () => {
	const template = useRef<any>(null);

	const handleCopy = useCallback(async () => {
		const selection = window.getSelection();
		const range = document.createRange();

		range.selectNodeContents(template.current);

		selection?.removeAllRanges();
		selection?.addRange(range);

		document.execCommand('copy');

		selection?.empty();

		alert('Signatur erfolgreich kopiert.');
	}, [template]);

	return (
		<div className="flex flex-col items-center justify-center w-screen h-screen">
			<Box p={4} className="w-full max-w-[720px]">
				<Box p={4} mb={4} className="relative border border-gray-100 rounded">
					<Template ref={template} />
					<div className="absolute left-0 top-0 w-full h-full rounded-lg pointer-events-none" />
				</Box>
				<Box className="flex justify-between">
					<Link to="/create">
						<Button>Zurück</Button>
					</Link>
					<Button onClick={handleCopy}>Kopieren</Button>
				</Box>
			</Box>
		</div>
	);
};

export default Preview;
