import { useFormikContext } from 'formik';
import { forwardRef, useMemo } from 'react';

import { FormValues } from '../App/App';
import Table from '../Table';

const Template = forwardRef<HTMLTableElement, any>((props, ref) => {
	const { values } = useFormikContext<FormValues>() ?? {};

	const blockStyle = useMemo(() => ({
		display: 'block',
	}), []);

	const inlineBlockStyle = useMemo(() => ({
		display: 'inline-block',
	}), []);

	const fontStyleNormal = useMemo(() => ({
		color: '#000000',
		fontSize: '10pt',
		lineHeight: '14pt',
		fontFamily: 'Arial, sans-serif',
	}), []);

	const fontStyleBold = useMemo(() => ({
		color: '#000000',
		fontSize: '10pt',
		lineHeight: '14pt',
		fontFamily: 'Arial, sans-serif',
		fontWeight: 700,
	}), []);

	const fontStyleUnderline = useMemo(() => ({
		textDecoration: 'underline',
	}), []);

	const fontStyleRed = useMemo(() => ({
		color: '#d70000',
	}), []);

	const socialMediaLinkStyle = useMemo(() => ({
		width: '32px',
		height: '24px',
		display: 'block',
		textDecoration: 'none',
	}), []);

	const socialMediaImageStyle = useMemo(() => ({
		width: '24px',
		height: '24px',
		display: 'block',
	}), []);

	const css = `
    #rs-signature {
      width: 435px;
    }

    #rs-signature * {
      border: 0;
      padding: 0;
      margin: 0;
    }
	`;

	return (
		<div style={{ width: '435px' }}>
			<style>{css}</style>
			<Table ref={ref} id="rs-signature" width="435px">
				<tr>
					<td colSpan={2}>
						<div style={{ ...fontStyleNormal, ...blockStyle }}>Freundliche Grüsse</div>
						<div style={{ ...fontStyleBold, ...blockStyle }}>{values?.name}</div>
					</td>
				</tr>
				<tr>
					<td
						colSpan={2}
						style={{
							height: '16px',
						}}
					/>
				</tr>
				<tr>
					<td
						colSpan={2}
						style={{
							height: '1px',
							backgroundColor: '#000000',
						}}
					/>
				</tr>
				<tr>
					<td
						colSpan={2}
						style={{
							height: '16px',
						}}
					/>
				</tr>
				<tr>
					<td
						style={{
							width: '122px',
							verticalAlign: 'top',
						}}
					>
						<a
							href="https://richnerstutz.ch"
							style={{
								display: 'block',
								width: '98px',
								height: '82px',
								textDecoration: 'none',
							}}
						>
							<img
								width="98"
								height="82"
								alt="Richnerstutz AG"
								src={`https://assets.rs.dev/pulpo.png?cache=${new Date().getTime()}`}
							/>
						</a>
					</td>
					<td
						style={{
							width: '313px',
							verticalAlign: 'top',
						}}
					>
						<div style={{ ...fontStyleBold, ...blockStyle }}>Richnerstutz AG</div>
						<div style={{ ...fontStyleNormal, ...blockStyle }}>{values.name}</div>
						<div style={{ ...fontStyleNormal, ...blockStyle }}>{values.position}</div>
						{values.role && (<div style={{ ...fontStyleNormal, ...blockStyle }}>{values.role}</div>)}
						<div style={{ ...fontStyleNormal, ...blockStyle }}>Nordstrasse 7, 5612 Villmergen</div>
						<div style={{ ...fontStyleNormal, ...blockStyle }}>{values.phone}</div>
						{values.mobile && (<div style={{ ...fontStyleNormal, ...blockStyle }}>{values.mobile}</div>)}
						<div>
							<a style={{ ...fontStyleNormal, ...blockStyle, ...fontStyleUnderline }} href={`mailto:${values.email}`}>{values.email}</a>
						</div>
						<div style={{ fontSize: '0px', lineHeight: '0' }}>
							<img
								width={313}
								height={12}
								alt="spacer"
								style={{ width: '313px', height: '12px', display: 'block' }}
								src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
							/>
						</div>
						<div style={{ height: '24px', display: 'block' }}>
							<a href="https://linkedin.com/company/3848260" style={{ ...socialMediaLinkStyle, ...inlineBlockStyle }}>
								<img
									alt="LinkedIn"
									src={`https://assets.rs.dev/linkedin-black.png?cache=${new Date().getTime()}`}
									style={{ ...socialMediaImageStyle, ...blockStyle }}
									width="24"
									height="24"
								/>
							</a>
							<a href="https://de-de.facebook.com/richnerstutz" style={{ ...socialMediaLinkStyle, ...inlineBlockStyle }}>
								<img
									alt="Facebook"
									src={`https://assets.rs.dev/facebook-black.png?cache=${new Date().getTime()}`}
									style={{ ...socialMediaImageStyle, ...blockStyle }}
									width="24"
									height="24"
								/>
							</a>
							<a href="https://instagram.com/richnerstutz/?hl=de" style={{ ...socialMediaLinkStyle, ...inlineBlockStyle }}>
								<img
									alt="Instagram"
									src={`https://assets.rs.dev/instagram-black.png?cache=${new Date().getTime()}`}
									style={{ ...socialMediaImageStyle, ...blockStyle }}
									width="24"
									height="24"
								/>
							</a>
						</div>
						{values.note && (
							<>
								<div style={{ fontSize: '0px', lineHeight: '0' }}>
									<img
										width={313}
										height={8}
										alt="spacer"
										style={{ width: '313px', height: '8px', display: 'block' }}
										src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
									/>
								</div>
								<div style={{ ...fontStyleNormal, ...fontStyleRed, ...blockStyle }}>{values.note}</div>
							</>
						)}
					</td>
				</tr>
			</Table>
			<div style={{ float: 'none', clear: 'both' }} />
		</div>
	);
});

export default Template;
